/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { useProductDetails } from '@/data/Content/ProductDetails';
import { useScene7ImgUrl } from '@/data/Content/Scene7ImgUrl';
import { useCustomPromo } from '@/data/Content/_CustomPromos';
import { useExtraRequestParameters } from '@/data/Content/_ExtraRequestParameters';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { productFetcher } from '@/data/Content/_Product';
import { useSettings } from '@/data/Settings';
import { useUser } from '@/data/User';
import { ContentContext } from '@/data/context/content';
import { ProductAttributeValue, ProductType } from '@/data/types/Product';
import { extractContentsArray } from '@/data/utils/extractContentsArray';
import { getClientSideCommon } from '@/data/utils/getClientSideCommon';
import { getContractIdParamFromContext } from '@/data/utils/getContractIdParamFromContext';
import { expand, shrink } from '@/data/utils/keyUtil';
import { MouseEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';

const DATA_KEY = 'FullProductLookup';

const findSkuBySwatch = (
	root: ProductType | undefined,
	swatch: ProductAttributeValue | undefined
) => {
	const found =
		swatch && root
			? root.items?.find(({ attributes }) =>
					attributes.find(({ values }) => values.find(({ id }) => id === swatch.id))
			  )
			: undefined;
	return found;
};

export const useProductCard = (product: ProductType) => {
	const router = useNextRouter();
	const { settings } = useSettings();
	const {
		langId,
		storeId,
		defaultCurrency: currency,
		defaultCatalogId: catalogId,
	} = getClientSideCommon(settings, router);
	const { user } = useUser();
	const requestParams = useExtraRequestParameters();
	const [swatch, setSwatch] = useState<ProductAttributeValue>();
	const [root, setRoot] = useState<ProductType>();
	const { promos } = useCustomPromo(product?.id);
	const { selectedAttributes } = useContext(ContentContext) as ReturnType<typeof useProductDetails>;
	const { data } = useSWR(
		!root?.items && swatch && storeId && product.partNumber
			? [
					shrink({
						storeId,
						currency,
						catalogId,
						langId,
						...getContractIdParamFromContext(user?.context),
						...(!user?.isEmployee || user?.onBehalfOf
							? { '_wcf.search.filter.expr': 'facets.7741124012283333342.value.raw:N' }
							: undefined),
						partNumber: product.partNumber,
					}),
					DATA_KEY,
			  ]
			: null,
		async ([props]) => productFetcher(true)(expand(props), requestParams)
	);
	const sku = useMemo(() => findSkuBySwatch(root, swatch), [root, swatch]);

	const selectedSwatchValue: any = selectedAttributes?.Color;

	const onSwatch = useCallback(
		async (event: MouseEvent<HTMLElement>, _swatch: ProductAttributeValue) => {
			event.stopPropagation();
			setSwatch(_swatch);
		},
		[]
	);

	useEffect(() => {
		// we will cache the parent product once it's fetched so ignore any null-ish responses
		if (data) {
			const parent = extractContentsArray(data)[0];
			setRoot(parent);
		}
	}, [data]);
	const { getMainImageUrl, getCrossSellUrl } = useScene7ImgUrl();
	let { thumbnail, altThumbnail, newThumbnail } = useMemo(() => {
		const thumbnailRaw = (sku ?? product).thumbnailRaw;
		return {
			thumbnail: thumbnailRaw ? getMainImageUrl(thumbnailRaw) : undefined,
			altThumbnail: getMainImageUrl(product.partNumber),
			newThumbnail: thumbnailRaw ? getCrossSellUrl(thumbnailRaw, selectedSwatchValue) : undefined,
		};
	}, [getMainImageUrl, getCrossSellUrl, selectedSwatchValue, product, sku]);

	const splashTitle: string | null = useMemo(() => {
		let splashTitle = null;
		product?.descriptiveAttributes
			?.filter((attr) => attr.identifier === 'splash_title')
			.forEach(({ values }) =>
				values.forEach((value) => {
					splashTitle = value.value;
				})
			);
		return splashTitle;
	}, [product.descriptiveAttributes]);

	const splashPromo: string | null = useMemo(() => {
		let splashPromo = null;
		promos?.forEach((promo) => {
			splashPromo = promo;
		});
		return splashPromo;
	}, [promos]);

	return {
		onSwatch,
		sku,
		thumbnail,
		altThumbnail,
		newThumbnail,
		splashTitle,
		splashPromo,
		selectedSwatchValue,
	};
};
